import React, { Component }  from "react"
import { navigate } from "gatsby"
import { 
  Card, 
  Frame,
  Layout, 
  Loading,
  Page,
  } from "@shopify/polaris"

class IndexPage extends Component {
  componentDidMount = async () => {
    console.log('index page!')
    if (typeof window !== 'undefined') {
      navigate(
        `/app/`,
        {
          replace: true,
        }
      )
    }
  }

  render = () => {
    return (
      <Frame>
        <Loading />
        <Page
          fullWidth
          title="Authenticating..."
        >

          <Layout>
            <Layout.Section>
              <Card>
                <Card.Section>
                  <p>Authenticating...</p>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </Frame>
    )
  }
}

export default IndexPage
